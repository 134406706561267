<script>
    /* eslint-disable */

    import printHeader from '@components/print/header';
    import PrintMixin from "@src/mixins/PrintMixin";


    export default {
        page: {title: 'Άρθρο'},
        components: {printHeader},

        mixins: [PrintMixin],
        data() {
            return {
                article: {},
                articleId: 0,
                categories: [],
                isPrinting: false,
            }
        },
        created() {
            this.articleId = this.$route.params.articleId;
            this.fetchLibraryCategories();
            this.fetchArticle();
        },

        methods:{
            fetchLibraryCategories(){
                this.$axios.get(`/library/categories`, ).then(result => {
                    this.categories = result.data;
                }).catch(e => {
                    this.$notify({group: 'athlisis-notifications', type:'error', duration:5000, title: this.$t('errors.title'), text: 'Η φόρτωση των κατηγοριών απέτυχε. Παρακαλώ δοκιμάστε ξανά!'});
                });
            },

            fetchArticle(){
                this.$axios.get(`/library/${this.articleId}`).then(result => {
                    this.article = result.data;
                  this.$store.commit('ui/setActiveItem', {value: this.article.title, index: 0});
                }).catch(e => {
                    this.$notify({group: 'athlisis-notifications', type:'error', duration:5000, title: this.$t('errors.title'), text: 'Η φόρτωση των ενημερώσεων απέτυχε. Παρακαλώ δοκιμάστε ξανά!'});
                });
            },

            getCategory(id){
                return this.categories.find(x => {return x.id === id}).name;
            },

            printArticle() {
              this.isPrinting = true;

              this.$nextTick(() => {
                let divToPrint = this.$refs['article-print-area'].outerHTML;
                this.isPrinting = false;
                divToPrint = divToPrint.replaceAll("<p><br></p>", "");
                this.print(divToPrint);
              })
            }
        }
    }
</script>
<style lang="scss">
    #article{
        .card-body{
            .card-body{
                padding:0;
                margin-top:30px;
            }
        }
        p{
            margin-bottom:0px;
        }
    }
</style>

<template>
    <div id="article-layout">
        <div id="article" ref="article-print-area" class="row">
            <div class="col-md-12">
                <b-card v-if="article">
                    <b-button variant="primary" size="xs" @click="$router.push('/library')" class="mb-3"><i class="fa fa-long-arrow-alt-left"></i> Επιστροφή στη βιβλιοθήκη</b-button>
                    <b-button variant="success" class="mb-2 float-right" @click="printArticle"><i class="fe-printer"></i> Εκτύπωση</b-button>

                    <print-header/>
                    <b-card-title class="article-title">{{article.title}}</b-card-title>
                    <b-card-sub-title v-if="categories.length > 0 && article.articleCategoryId" class="article-category">Δημοσιεύτηκε στην κατηγορία: {{getCategory(article.articleCategoryId)}}</b-card-sub-title>
                    <b-card-body class="article-body"><div v-html="article.content"></div></b-card-body>
                    <b-card-footer class="mt-4 article-footer"><center>Δημοσιεύτηκε στο athlisis.com | Μια εφαρμογή από διατροφολόγους για διατροφολόγους</center></b-card-footer>
                </b-card>
            </div>

        </div>
    </div>


</template>
